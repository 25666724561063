<template>
  <validation-observer ref="bankRules" tag="form">
    <h4>Dados Gerais</h4>

    <b-row>
      <b-col md="6">
        <b-form-group label="Banco" label-for="dadoBancarioBanco">
          <validation-provider #default="{ errors }" name="Banco" rules="required">
            <v-select-pt
              v-model="bancosCampos.bancoId"
              :options="banco"
              :reduce="(option) => option.id"
              label="nome"
            ></v-select-pt>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Agência" label-for="dadoBancarioAgencia">
          <validation-provider #default="{ errors }" name="Agência" rules="required">
            <b-form-input
              id="dadoBancarioAgencia"
              type="text"
              placeholder="Agência"
              v-model="bancosCampos.dadoBancarioAgencia"
              v-bloqueioCaractereMask="{ tamanho: 15, regex: /\D/g }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="DV" label-for="dadoBancarioAgenciaDV">
          <validation-provider #default="{ errors }" name="DV" rules="required">
            <b-form-input
              id="dadoBancarioAgenciaDV"
              type="text"
              placeholder="DV"
              v-model="bancosCampos.dadoBancarioAgenciaDV"
              v-bloqueioCaractereMask="{ tamanho: 15, regex: /\D/g }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-form-group label="Tipo de Conta" label-for="contaOpcao">
          <validation-provider #default="{ errors }" name="Tipo de Conta" rules="required">
            <b-form-select id="contaOpcao" :options="contaOpcoes" v-model="bancosCampos.dadoBancarioContaOpcao" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Conta Corrente" label-for="dadoBancarioConta">
          <validation-provider #default="{ errors }" name="Conta Corrente" rules="required">
            <b-form-input
              id="dadoBancarioConta"
              type="text"
              placeholder="Conta Corrente"
              v-model="bancosCampos.dadoBancarioConta"
              v-bloqueioCaractereMask="{ tamanho: 15, regex: /\D/g }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Conta Corrente DV" label-for="dadoBancarioContaDV">
          <validation-provider #default="{ errors }" name="Conta Corrente DV" rules="required">
            <b-form-input
              id="dadoBancarioContaDV"
              type="text"
              placeholder="Conta Corrente DV"
              style="padding-right: 10px; padding-left: 10px"
              v-model="bancosCampos.dadoBancarioContaDV"
              v-bloqueioCaractereMask="{ tamanho: 15, regex: /\D/g }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="OP" label-for="dadoBancarioContaOP">
          <validation-provider #default="{ errors }" name="OP" rules="required">
            <b-form-input
              id="dadoBancarioContaOP"
              type="text"
              placeholder="OP"
              v-model="bancosCampos.dadoBancarioContaOP"
              v-bloqueioCaractereMask="{ tamanho: 15, regex: /\D/g }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="border-top mt-1 mb-1"></b-row>
    <h4 class="mt-2 mb-1">Dados para Remessa Bancária</h4>

    <b-row>
      <b-col md="4">
        <b-form-group label="Carteira" label-for="carteira">
          <!-- <validation-provider #default="{ errors }" name="Carteira" rules="required"> -->
          <b-form-input id="carteira" type="text" placeholder="Carteira" v-model="bancosCampos.carteira" />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código Cedente" label-for="codigoCedente">
          <!-- <validation-provider #default="{ errors }" name="Código Cedente" rules="required"> -->
          <b-form-input id="codigoCedente" type="text" placeholder="Código Cedente" v-model="bancosCampos.codigoCedente" />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código Cedente DV" label-for="codigoCedenteDV">
          <!-- <validation-provider #default="{ errors }" name="Código Cedente DV" rules="required"> -->
          <b-form-input
            id="codigoCedenteDV"
            type="text"
            placeholder="Código Cedente DV"
            v-model="bancosCampos.codigoCedenteDV"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-form-group label="Código Convênio" label-for="codigoConvenio">
          <!-- <validation-provider #default="{ errors }" name="Código Convênio" rules="required"> -->
          <b-form-input id="codigoConvenio" type="text" placeholder="Código Convênio" v-model="bancosCampos.codigoConvenio" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Variação" label-for="variacao">
          <!-- <validation-provider #default="{ errors }" name="Variação" rules="required"> -->
          <b-form-input id="variacao" type="text" placeholder="Variação" v-model="bancosCampos.variacao" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Apelido" label-for="apelido">
          <!-- <validation-provider #default="{ errors }" name="Apelido" rules="required"> -->
          <b-form-input id="apelido" type="text" placeholder="Apelido" v-model="bancosCampos.apelido" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-form-group label="Tipo de Compromisso" label-for="tipoCompromisso">
          <!-- <validation-provider #default="{ errors }" name="Tipo de Compromisso" rules="required"> -->
          <b-form-input
            id="tipoCompromisso"
            type="text"
            placeholder="Tipo de Compromisso"
            v-model="bancosCampos.tipoCompromisso"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código do Compromisso" label-for="codigoCompromisso">
          <!-- <validation-provider #default="{ errors }" name="Código do Compromisso" rules="required"> -->
          <b-form-input
            id="codigoCompromisso"
            type="text"
            placeholder="Código do Compromisso"
            v-model="bancosCampos.codigoCompromisso"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Parâmetro de Transmissão" label-for="parametroTransmissao">
          <!-- <validation-provider #default="{ errors }" name="Parâmetro de Transmissão" rules="required"> -->
          <b-form-input
            id="parametroTransmissao"
            type="text"
            placeholder="Parâmetro de Transmissão"
            v-model="bancosCampos.parametroTransmissao"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="border-top mt-1 mb-1"></b-row>
    <h4 class="mt-2 mb-1">Dados para Remessa de Boletos</h4>

    <b-row>
      <b-col md="4">
        <b-form-group label="Carteira" label-for="carteira">
          <!-- <validation-provider #default="{ errors }" name="Carteira" rules="required"> -->
          <b-form-input id="carteira" type="text" placeholder="Carteira" v-model="bancosCampos.carteiraBoleto" />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código Cedente" label-for="codigoCedente">
          <!-- <validation-provider #default="{ errors }" name="Código Cedente" rules="required"> -->
          <b-form-input
            id="codigoCedente"
            type="text"
            placeholder="Código Cedente"
            v-model="bancosCampos.codigoCedenteBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código Cedente DV" label-for="codigoCedenteDV">
          <!-- <validation-provider #default="{ errors }" name="Código Cedente DV" rules="required"> -->
          <b-form-input
            id="codigoCedenteDV"
            type="text"
            placeholder="Código Cedente DV"
            v-model="bancosCampos.codigoCedenteDVBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-form-group label="Código Convênio" label-for="codigoConvenio">
          <!-- <validation-provider #default="{ errors }" name="Código Convênio" rules="required"> -->
          <b-form-input
            id="codigoConvenio"
            type="text"
            placeholder="Código Convênio"
            v-model="bancosCampos.codigoConvenioBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Variação" label-for="variacao">
          <!-- <validation-provider #default="{ errors }" name="Variação" rules="required"> -->
          <b-form-input id="variacao" type="text" placeholder="Variação" v-model="bancosCampos.variacaoBoleto" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Apelido" label-for="apelido">
          <!-- <validation-provider #default="{ errors }" name="Apelido" rules="required"> -->
          <b-form-input id="apelido" type="text" placeholder="Apelido" v-model="bancosCampos.apelidoBoleto" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-form-group label="Tipo de Compromisso" label-for="tipoCompromisso">
          <!-- <validation-provider #default="{ errors }" name="Tipo de Compromisso" rules="required"> -->
          <b-form-input
            id="tipoCompromisso"
            type="text"
            placeholder="Tipo de Compromisso"
            v-model="bancosCampos.tipoCompromissoBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Código do Compromisso" label-for="codigoCompromisso">
          <!-- <validation-provider #default="{ errors }" name="Código do Compromisso" rules="required"> -->
          <b-form-input
            id="codigoCompromisso"
            type="text"
            placeholder="Código do Compromisso"
            v-model="bancosCampos.codigoCompromissoBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Parâmetro de Transmissão" label-for="parametroTransmissao">
          <!-- <validation-provider #default="{ errors }" name="Parâmetro de Transmissão" rules="required"> -->
          <b-form-input
            id="parametroTransmissao"
            type="text"
            placeholder="Parâmetro de Transmissão"
            v-model="bancosCampos.parametroTransmissaoBoleto"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="p-1">
      <b-button variant="primary" @click="bancos().salvar()" :disabled="loading">
        {{ !loading ? 'Salvar' : '' }}
        <b-spinner small v-if="loading" />
      </b-button>
    </b-row>

    <b-row>
      <b-table
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="bancarioitems"
        :fields="bancariofields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
            <b-dropdown-item @click="contas().editar(row.item)">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Conta</span>
            </b-dropdown-item>
            <b-dropdown-item @click="contas().excluir(row)">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(dadoBancarioContaOpcao)="row">
          {{
            row.item.dadoBancarioContaOpcao == 'EntradaeSaida'
              ? 'Entrada e Saída'
              : row.item.dadoBancarioContaOpcao == 'Saida'
              ? 'Saída'
              : row.item.dadoBancarioContaOpcao
          }}
        </template>
      </b-table>
    </b-row>
  </validation-observer>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { Ufs, isLetter, formatarData, formatarDataGlobal, validarData } from '@/libs/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { v4 } from 'uuid'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      FormWizard,
      TabContent,
      formatarData,
      Cleave,
    },
    props: {
      instId: {
        type: Number,
        default: undefined,
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        bancarioitems: [],
        bancariofields: [
          { key: 'opcoes', label: 'Opções', opcaoOnOff: true },
          { key: 'dadoBancarioContaOpcao', label: 'Tipo de Conta', sortable: true },
          { key: 'dadoBancarioConta', label: 'Conta Corrente', sortable: true },
          { key: 'dadoBancarioContaDV', label: 'Conta DV', sortable: true },
          { key: 'dadoBancarioAgencia', label: 'Agência', sortable: true },
          { key: 'dadoBancarioAgenciaDV', label: 'Agência DV', sortable: true },
        ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        bancosCampos: {},
        loading: false,
        pesquisar: {},
        banco: [],
        contaOpcoes: [
          { value: 'Entrada', text: 'Entrada' },
          { value: 'Saida', text: 'Saída' },
          { value: 'EntradaeSaida', text: 'Entrada e Saída' },
        ],
        perPage: 10,
      }
    },
    async mounted() {
      this.banco = await this.bancos().listarOpcoes()
      if (this.instId) {
        this.bancarioitems = await this.bancos().listarGrid(this.instId)
      }
    },
    methods: {
      contas() {
        return {
          editar: (conta) => {
            this.bancosCampos = conta
          },
          excluir: async (conta) => {
            const confirmed = this.$swal({
              title: 'Atenção!',
              text: 'Deseja realmente excluir a conta selecionada?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim!',
              cancelButtonText: 'Não!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            })
            if (confirmed) {
              const index = this.bancarioitems.findIndex((item) => item.id === conta.id)
              this.bancarioitems.splice(index, 1)
            }
          },
        }
      },
      bancos() {
        return {
          listarOpcoes: async () => {
            return (await useJwt.get('utils/bancos')).data
          },
          salvar: async () => {
            const validationResult = await this.$refs.bankRules.validate()
            if (!validationResult) return

            const itemIndex = this.bancarioitems.findIndex((item) => item.id === this.bancosCampos.id)
            if (itemIndex !== -1) {
              this.bancarioitems[itemIndex] = this.bancosCampos
            } else {
              this.bancarioitems.push({
                id: v4(),
                shouldInsert: true,
                ...this.bancosCampos,
              })
            }

            this.bancosCampos = {}
            this.$refs.bankRules.reset()
          },
          persistir: async () => {
            const { data } = await useJwt.post('cadastro/instituto/conta-bancaria', {
              institutoId: this.instId,
              ...this.bancosCampos,
            })
          },
          listarGrid: async (instId) => {
            return (await useJwt.get(`cadastro/instituto/bancarioInst/${instId}`)).data
          },
        }
      },
    },
    watch: {
      bancarioitems: {
        handler: function (val, oldVal) {
          this.$emit('atualizaBancarioItems', val)
        },
        deep: true,
      },
    },
  }
</script>
