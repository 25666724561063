<template>
  <b-card-code no-body>
    <b-card-body>
      <b-row v-show="this.acesso.CadastroInstitutoConsulta">
        <b-col md="2">
          <b-form-group label="Pesquisar Por" label-for="opcao">
            <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input
              id="descricao"
              placeholder="Descrição"
              v-model="pesquisar.descricao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>
        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="pesquisarInstituto()"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
        <b-col md="auto" align="right" v-show="this.acesso.CadastroInstitutoCriarNovo">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="novoRegistro()"
          >
            Novo Cadastro
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" align="right" v-show="!this.acesso.CadastroInstitutoConsulta && this.acesso.CadastroInstitutoCriarNovo">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="novoRegistro()"
          >
            Novo Cadastro
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-col md="12" align="left" v-show="!this.acesso.CadastroInstitutoExcluir && !this.acesso.CadastroInstitutoEditar">
      <b-card-text class="mb-1 text-danger">
        A coluna "OPÇÕES" está desativada. Seu usuário não tem essa permissão
      </b-card-text>
    </b-col>

    <b-table
      v-show="totalRows > 0 || isBusy"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong> Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row" v-if="acesso.CadastroInstitutoExcluir && acesso.CadastroInstitutoEditar">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item @click="editar(row.item.id)" v-show="acesso.CadastroInstitutoEditar">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="excluirVerifica(row.item.id)" v-show="acesso.CadastroInstitutoExcluir">
            <feather-icon icon="XIcon" size="16" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body v-if="totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Por Página"
        style="align-items: center"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <b-modal
      id="modal-cadastro"
      ref="modal-cadastro"
      centered
      size="lg"
      title="Cadastro da Unidade Gestora"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div>
        <form-wizard
          color="#3949ab"
          :title="null"
          :subtitle="null"
          next-button-text="Próximo"
          finish-button-text="Finalizar"
          back-button-text="Anterior"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- Dados Cadastrais -->
          <tab-content title="Dados Cadastrais" :before-change="validationDadosCadastrais">
            <validation-observer ref="cadRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="CNPJ" label-for="CNPJ">
                    <validation-provider #default="{ errors }" name="CNPJ" rules="required">
                      <Cleave
                        id="CNPJ"
                        type="text"
                        placeholder="##.###.###/####-##"
                        v-model="dados.cnpj"
                        :raw="false"
                        :options="options.cleaveCNPJ"
                        class="form-control"
                        @blur="isCNPJValid"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nome" label-for="nome">
                    <validation-provider #default="{ errors }" name="Nome" rules="required">
                      <b-form-input id="nome" type="text" placeholder="Nome" v-model="dados.nome" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-form-group label="Razão Social" label-for="razaoSocial">
                    <validation-provider #default="{ errors }" name="Razão Social" rules="required">
                      <b-form-input id="razaoSocial" type="text" placeholder="Razão Social" v-model="dados.razaoSocial" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Sigla" label-for="sigla">
                    <validation-provider #default="{ errors }" name="Sigla" rules="required">
                      <b-form-input
                        id="sigla"
                        type="text"
                        placeholder="Sigla"
                        v-model="dados.sigla"
                        style="text-transform: uppercase"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Nome do responsável" label-for="nomeResponsavel">
                    <validation-provider #default="{ errors }" name="Nome do responsável" rules="required">
                      <b-form-input
                        id="nomeResponsavel"
                        type="text"
                        placeholder="Nome do responsável"
                        v-model="dados.nomeResponsavel"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="CPF do responsável" label-for="cpfResponsavel">
                    <validation-provider #default="{ errors }" name="CPF do responsável" rules="required">
                      <Cleave
                        id="cpfResponsavel"
                        type="text"
                        placeholder="CPF do responsável"
                        v-model="dados.cpfResponsavel"
                        :raw="false"
                        :options="options.cleaveCPF"
                        class="form-control"
                        @blur="isCPFValid"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Cargo do responsável" label-for="cargoResponsavel">
                    <validation-provider #default="{ errors }" name="Cargo" rules="required">
                      <b-form-input id="cargoResponsavel" type="text" placeholder="Cargo" v-model="dados.cargoResponsavel" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Data início gestão" label-for="dtInicioGestao">
                    <validation-provider #default="{ errors }" name="Data início gestão" rules="required">
                      <Cleave
                        id="dtInicioGestao"
                        class="form-control"
                        type="text"
                        placeholder="DD/MM/AAAA"
                        v-model="dados.dtInicioGestao"
                        :options="options.cleaveDate"
                        :raw="false"
                        @blur="isDateValid"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="E-mail" label-for="emailResponsavel">
                    <validation-provider #default="{ errors }" name="E-mail" rules="required">
                      <b-form-input
                        id="emailResponsavel"
                        type="email"
                        @blur="isEmailValid"
                        placeholder="email@provedor.com"
                        v-model="dados.emailResponsavel"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Telefone" label-for="telefone">
                    <Cleave
                      id="telefone"
                      v-model="dados.telefone"
                      class="form-control"
                      :raw="false"
                      :options="options.cleavePhone"
                      placeholder="Telefone"
                      rules="required"
                      @blur="isPhoneValid"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- CRP  -->
          <tab-content title="CRP" :before-change="validationCRP">
            <validation-observer ref="crpRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Data de validade" label-for="crpValidade">
                    <Cleave
                      id="crpValidade"
                      class="form-control"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      v-model="dados.crpValidade"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Status do CRP" label-for="crpStatus">
                    <b-form-input id="crpStatus" type="text" :readonly="true" v-model="dados.crpStatus" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <div class="flex w-full items-center justify-center" id="app">
                    <div class="p-12 bg-gray-100">
                      <b-form-group v-if="!this.documentoCRP.id" label="Documento do CRP" label-for="fileCRP">
                        <b-form-file
                          :state="null"
                          ref="file"
                          accept=".pdf"
                          placeholder="Escolha um arquivo ou arraste até aqui ..."
                          drop-placeholder="Solte o arquivo aqui..."
                          browse-text="Escolher"
                        >
                        </b-form-file>
                      </b-form-group>
                    </div>
                  </div>
                </b-col>
                <b-col md="3" class="flex items-center breakWord">
                  <div class="flex w-full items-center justify-center" id="app">
                    <div class="p-12 bg-gray-100" style="align-items: center">
                      <a href="#" v-if="this.documentoCRP" v-cloak @click="downloadArquivo()">
                        {{ this.documentoCRP.originalName }}</a
                      >
                    </div>
                  </div>
                </b-col>
                <b-col v-if="this.documentoCRP.id" class="flexRowReverse" md="3">
                  <b-button variant="danger" @click="excluirArquivo()">Excluir</b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Endereço -->
          <tab-content title="Endereço" :before-change="validationEndereco">
            <validation-observer ref="enderecoRules" tag="form">
              <b-row>
                <b-col md="3">
                  <b-form-group label="CEP" label-for="cep">
                    <validation-provider #default="{ errors }" name="CEP" rules="required|cep">
                      <Cleave
                        id="cep"
                        type="text"
                        placeholder="CEP"
                        v-model="dados.cep"
                        maxlength="10"
                        @keyup.native="buscarCEP"
                        :raw="false"
                        :options="options.cleaveCEP"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Logradouro" label-for="logradouro">
                    <validation-provider #default="{ errors }" name="Logradouro" rules="required">
                      <b-form-input id="logradouro" type="text" placeholder="Logradouro" v-model="dados.logradouro" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Bairro" label-for="bairro">
                    <validation-provider #default="{ errors }" name="Bairro" rules="required">
                      <b-form-input id="bairro" type="text" placeholder="Bairro" v-model="dados.bairro" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Número" label-for="numero">
                    <validation-provider #default="{ errors }" name="Número" rules="required">
                      <b-form-input id="numero" type="text" placeholder="Número" v-model="dados.numero" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Complemento" label-for="complemento">
                    <b-form-input id="complemento" type="text" placeholder="Complemento" v-model="dados.complemento" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="UF" label-for="uf">
                    <validation-provider #default="{ errors }" name="UF" rules="required">
                      <b-form-select
                        name="uf"
                        v-model="dados.uf"
                        :options="ufOpcoes"
                        v-on:change="carregarCidadeEndereco"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Cidade" label-for="cidade">
                    <validation-provider #default="{ errors }" name="Cidade" rules="required">
                      <v-select-pt
                        name="cidade"
                        v-model="dados.cidade"
                        :options="cidade"
                        label="responsavelCidade"
                      ></v-select-pt>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <small class="text-primary d-block"
                      >Digite o CEP, ou selecione a UF, para que as cidades sejam carregadas</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Dados Bancários  -->
          <tab-content title="Dados Bancários" :before-change="validationBanco">
            <InstitutoDadosBancarios :instId="dados.id" @atualizaBancarioItems="atualizaBancarioItems" />
          </tab-content>
        </form-wizard>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, cep, rule_cep } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { Ufs, isLetter, formatarData, formatarDataGlobal, validarData } from '@/libs/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { v4 } from 'uuid'
  import InstitutoDadosBancarios from './instituto/InstitutoDadosBancarios'
  import * as dateFns from 'date-fns'

  export default {
    components: {
      InstitutoDadosBancarios,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      FormWizard,
      TabContent,
      formatarData,
      Cleave,
      rule_cep,
      cep,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        opcaoOnOff: false,
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        carregando: false,
        required,
        contaOpcoes: [
          { value: 'Entrada', text: 'Entrada' },
          { value: 'Saida', text: 'Saída' },
          { value: 'EntradaeSaida', text: 'Entrada e Saída' },
        ],
        crpOpcoes: [
          { value: 'Valido', text: 'Válido' },
          { value: 'NaoValido', text: 'Não Válido' },
        ],
        tipoOpcoes: [
          { value: 1, text: 'Nome da Unidade Gestora' },
          { value: 2, text: 'CNPJ' },
          { value: 3, text: 'Nome do Responsável' },
        ],
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        fields: [
          { key: 'opcoes', label: 'Opções', opcaoOnOff: true },
          { key: 'nome', label: 'Nome da Unidade Gestora', sortable: true },
          { key: 'cnpj', label: 'CNPJ', sortable: true },
          { key: 'nomeResponsavel', label: 'Nome do Responsável', sortable: true },
          { key: 'cargoResponsavel', label: 'Cargo do Responsável', sortable: true },
          { key: 'emailResponsavel', label: 'Email do Responsável', sortable: true },
          { key: 'telefone', label: 'Telefone' },
        ],
        items: [],
        bancariofields: [
          { key: 'opcoes', label: 'Opções', opcaoOnOff: true },
          { key: 'dadoBancarioContaOpcao', label: 'Tipo de Conta', sortable: true },
          { key: 'dadoBancarioConta', label: 'Conta Corrente', sortable: true },
          { key: 'dadoBancarioContaDV', label: 'Conta DV', sortable: true },
          { key: 'dadoBancarioAgencia', label: 'Agência', sortable: true },
          { key: 'dadoBancarioAgenciaDV', label: 'Agência DV', sortable: true },
        ],
        bancarioitems: [],
        dados: { crpValidade: '' },
        bancosCampos: {},
        buscaOrgao: {},
        acesso: {},
        loading: false,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveCNPJ: {
            delimiters: ['.', '.', '/', '-'],
            numericOnly: true,
            blocks: [2, 3, 3, 4, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleavePhone: {
            numericOnly: true,
            delimiters: ['(', ')', ' ', '-'],
            blocks: [0, 2, 0, 5, 4],
          },
          cleaveCEP: {
            delimiters: ['.', '-'],
            blocks: [2, 3, 3],
          },
        },
        banco: [],
        cidade: [],
        ufOpcoes: Ufs,
        isBusy: false,
        isLetter,
        number: {},
        filelist: [],
        documentoCRP: [],
        documentoAnexado: [],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.carregarBancos()

      if (this.userData.role == 'master') {
        this.acesso.CadastroInstitutoConsulta = true
        this.acesso.CadastroInstitutoCriarNovo = true
        this.acesso.CadastroInstitutoEditar = true
        this.acesso.CadastroInstitutoExcluir = true
      } else {
        this.acesso.CadastroInstitutoConsulta = this.userData.GrupoAcesso.CadastroInstitutoConsulta
        this.acesso.CadastroInstitutoCriarNovo = this.userData.GrupoAcesso.CadastroInstitutoCriarNovo
        this.acesso.CadastroInstitutoEditar = this.userData.GrupoAcesso.CadastroInstitutoEditar
        this.acesso.CadastroInstitutoExcluir = this.userData.GrupoAcesso.CadastroInstitutoExcluir
      }
      // Conforme reunião retiramos a consulta automática
      // this.carregarGrid();
      this.items = []
      this.totalRows = 0
    },
    methods: {
      atualizaBancarioItems(items) {
        return (this.bancarioitems = items)
      },
      isPhoneValid() {
        if (this.dados.telefone == undefined) return false
        this.dados.telefone = this.dados.telefone.replace(/[^\d]+/g, '')
        if (
          this.dados.telefone == '0000000000000' ||
          this.dados.telefone == '000000000000' ||
          this.dados.telefone == '00000000000' ||
          this.dados.telefone == '0000000000' ||
          this.dados.telefone == '99999999999' ||
          this.dados.telefone == '9999999999' ||
          this.dados.telefone == '88888888888' ||
          this.dados.telefone == '8888888888' ||
          this.dados.telefone == '77777777777' ||
          this.dados.telefone == '7777777777' ||
          this.dados.telefone == '66666666666' ||
          this.dados.telefone == '6666666666' ||
          this.dados.telefone == '55555555555' ||
          this.dados.telefone == '5555555555' ||
          this.dados.telefone == '44444444444' ||
          this.dados.telefone == '4444444444' ||
          this.dados.telefone == '33333333333' ||
          this.dados.telefone == '3333333333' ||
          this.dados.telefone == '22222222222' ||
          this.dados.telefone == '2222222222' ||
          this.dados.telefone == '11111111111' ||
          this.dados.telefone == '1111111111'
        )
          return false
        else {
          return this.dados.telefone == ''
            ? ''
            : /^[\+]?[(]?[0-9]{2,4}[)]?[-\s\.]?[0-9]{4,5}[-\s\.]?[0-9]{4}$/im.test(this.dados.telefone)
            ? true
            : false
        }
      },
      isDateValid(data) {
        return validarData(data)
      },
      isEmailValid() {
        return this.dados.emailResponsavel == ''
          ? ''
          : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              this.dados.emailResponsavel,
            )
          ? true
          : false
      },
      isCNPJValid() {
        if (this.dados.cnpj == undefined) return false
        this.dados.cnpj = this.dados.cnpj.replace(/[^\d]+/g, '')
        if (this.dados.cnpj == '') return false
        if (this.dados.cnpj.length != 14) return false
        // Elimina CNPJs invalidos conhecidos
        else if (
          this.dados.cnpj == '00000000000000' ||
          this.dados.cnpj == '11111111111111' ||
          this.dados.cnpj == '22222222222222' ||
          this.dados.cnpj == '33333333333333' ||
          this.dados.cnpj == '44444444444444' ||
          this.dados.cnpj == '55555555555555' ||
          this.dados.cnpj == '66666666666666' ||
          this.dados.cnpj == '77777777777777' ||
          this.dados.cnpj == '88888888888888' ||
          this.dados.cnpj == '99999999999999'
        )
          return false
        else {
          // TODO: Refatorar esse código, muito feio
          // Valida DVs
          var tamanho = this.dados.cnpj.length - 2
          var numeros = this.dados.cnpj.substring(0, tamanho)
          var digitos = this.dados.cnpj.substring(tamanho)
          var soma = 0
          var pos = tamanho - 7
          for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2) pos = 9
          }
          let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
          if (resultado != digitos.charAt(0)) return false
          else {
            tamanho = tamanho + 1
            numeros = this.dados.cnpj.substring(0, tamanho)
            soma = 0
            pos = tamanho - 7
            for (i = tamanho; i >= 1; i--) {
              soma += numeros.charAt(tamanho - i) * pos--
              if (pos < 2) pos = 9
            }
            resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
            if (resultado != digitos.charAt(1)) return false
            else return true
          }
        }
      },
      isCPFValid() {
        if (this.dados.cpfResponsavel == undefined) return false
        this.dados.cpfResponsavel = this.dados.cpfResponsavel.replace(/[^\d]+/g, '')
        if (this.dados.cpfResponsavel == '') return false
        // Elimina CPFs invalidos conhecidos
        if (
          this.dados.cpfResponsavel.length != 11 ||
          this.dados.cpfResponsavel == '00000000000' ||
          this.dados.cpfResponsavel == '11111111111' ||
          this.dados.cpfResponsavel == '22222222222' ||
          this.dados.cpfResponsavel == '33333333333' ||
          this.dados.cpfResponsavel == '44444444444' ||
          this.dados.cpfResponsavel == '55555555555' ||
          this.dados.cpfResponsavel == '66666666666' ||
          this.dados.cpfResponsavel == '77777777777' ||
          this.dados.cpfResponsavel == '88888888888' ||
          this.dados.cpfResponsavel == '99999999999'
        )
          return false
        // Valida 1o digito
        // TODO: Refatorar esse código, muito feio
        var add = 0
        for (var i = 0; i < 9; i++) add += parseInt(this.dados.cpfResponsavel.charAt(i)) * (10 - i)
        var rev = 11 - (add % 11)
        if (rev == 10 || rev == 11) rev = 0
        if (rev != parseInt(this.dados.cpfResponsavel.charAt(9))) return false
        // Valida 2o digito
        add = 0
        for (i = 0; i < 10; i++) add += parseInt(this.dados.cpfResponsavel.charAt(i)) * (11 - i)
        rev = 11 - (add % 11)
        if (rev == 10 || rev == 11) rev = 0
        if (rev != parseInt(this.dados.cpfResponsavel.charAt(10))) return false
        return true
      },
      formSubmitted() {
        var mode = !this.dados.id ? 'INSERT' : 'UPDATE'
        var possuiErro = false
        var dadosValidados = false
        var uploadOk = this.documentoCRP.length != 0 ? false : true
        this.loading = true

        // Valida Dados Bancarios
        this.bancarioitems.countEntrada = 0
        this.bancarioitems.countSaida = 0
        this.bancarioitems.map((f) => {
          if (f.dadoBancarioContaOpcao == 'Entrada') {
            this.bancarioitems.countEntrada = parseInt(this.bancarioitems.countEntrada) + 1
          }
          if (f.dadoBancarioContaOpcao == 'Saida') {
            this.bancarioitems.countSaida = parseInt(this.bancarioitems.countSaida) + 1
          }
          if (f.dadoBancarioContaOpcao == 'EntradaeSaida') {
            this.bancarioitems.countEntrada = parseInt(this.bancarioitems.countEntrada) + 1
            this.bancarioitems.countSaida = parseInt(this.bancarioitems.countSaida) + 1
          }
        })

        if (this.bancarioitems.countEntrada > 0 && this.bancarioitems.countSaida > 0) {
          dadosValidados = true

          const dataToUpsert = {
            ...this.dados,
            cidade: this.dados.cidade,
            dtInicioGestao: this.dados.dtInicioGestao ? formatarDataGlobal(this.dados.dtInicioGestao) : null,
            crpValidade: this.dados.crpValidade ? formatarDataGlobal(this.dados.crpValidade) : null,
          }

          if (mode == 'INSERT') {
            //Realiza Cadastro de Instituto
            useJwt
              .post(`cadastro/instituto`, this.dados)
              .then((result) => {
                this.dados.id = result.data.id

                if (dadosValidados) {
                  // Cadastro de dados bancários
                  this.bancarioitems.map((item) => {
                    const bankDataUpsert = {
                      ...item,
                      institutoId: this.dados.id,
                    }
                    useJwt
                      .post(`cadastro/instituto/bancario`, bankDataUpsert)
                      .then((result) => {
                        this.loading = !this.loading
                        this.bancosCampos = {}
                      })
                      .catch((error) => {
                        this.loading = !this.loading
                        console.error(error)
                        var Title = 'Houve um erro ao cadastrar os dados bancários! Erro: ' + error.response.data.error
                        this.$message.error(Title)
                        reject()
                      })
                  })

                  // Cadastro de CRP Documento
                  if (this.documentoCRP.length > 0) {
                    const formData = new FormData()
                    formData.append('nome', 'CRPDocumento' + this.dados.sigla)
                    formData.append('institutoId', this.dados.id)
                    formData.append('file', this.documentoCRP)

                    useJwt
                      .postDocumento(`cadastro/instituto/uploadDocumentoCRP`, (event) => {}, formData)
                      .then((response) => {
                        uploadOk = true
                        this.$refs['modal-cadastro'].hide()
                        this.carregarGrid()
                      })
                      .catch((error) => {
                        this.$message.error(error.response.data.error)
                        possuiErro = true
                      })
                  }
                  var Title = 'Dados cadastrados com sucesso!'
                  this.carregarGrid()
                  this.msgSuccess(Title)
                }
              })
              .catch((error) => {
                console.error(error)
                possuiErro = true
                var Title = error.response.data.error
                this.$message.error(Title)
                reject()
              })
          } else {
            // -- UPDATE --
            if (dadosValidados == true) {
              var dadosBancariosArmazenados = {}

              useJwt
                .get(`cadastro/instituto/bancarioInst/${this.dados.id}`)
                .then((response) => {
                  dadosBancariosArmazenados = response.data
                  useJwt
                    .update(`cadastro/instituto`, this.dados.id, dataToUpsert)
                    .then((result) => {
                      if (dadosValidados) {
                        // -- Dados Bancários --
                        //Busca dados bancários que foram excluídos
                        var dadosBancariosAExcluir = []
                        var listaIDBancarioGrid = this.bancarioitems.map((item) => {
                          return item.id
                        })
                        if (dadosBancariosArmazenados)
                          var listaIDBancarioArmazenados = dadosBancariosArmazenados.map((item) => {
                            return item.id
                          })

                        // bancarioitems = dados na grid
                        listaIDBancarioArmazenados.map((item) => {
                          let indiceItem = listaIDBancarioGrid.indexOf(item)
                          if (indiceItem == -1) {
                            dadosBancariosAExcluir.push(item)
                          }
                        })

                        dadosBancariosAExcluir.map((item) => {
                          useJwt
                            .delete(`cadastro/instituto/deleteBancario`, item)
                            .then()
                            .catch((error) => {
                              possuiErro = true
                              console.error(error)
                              var Title = 'Houve um erro ao cadastrar os dados bancários! Erro: ' + error.response.data.error
                              this.$message.error(Title)
                              reject()
                            })
                        })

                        if (dadosBancariosAExcluir.length > 0) {
                          var Title = 'Dados bancários excluídos com sucesso!'
                          this.msgSuccess(Title)
                        }

                        this.bancarioitems.map((item) => {
                          const bankData = {
                            ...item,
                            institutoId: this.dados.id,
                          }

                          if (!item.shouldInsert) {
                            useJwt
                              .update(`cadastro/instituto/conta-bancaria`, item.id, item)
                              .then((response) => {
                                this.bancosCampos = {}
                              })
                              .catch((error) => {
                                possuiErro = true
                                console.error(error)
                                var Title = 'Houve um erro ao cadastrar os dados bancários! Erro: ' + error.response.data.error
                                this.$message.error(Title)
                                reject()
                              })
                          } else {
                            useJwt
                              .post(`cadastro/instituto/conta-bancaria`, bankData)
                              .then((result) => {
                                this.bancosCampos = {}
                              })
                              .catch((error) => {
                                possuiErro = true
                                console.error(error)
                                var Title = 'Houve um erro ao cadastrar os dados bancários! Erro: ' + error.response.data.error
                                this.$message.error(Title)
                                reject()
                              })
                          }
                        })

                        // -- Documento CRP --

                        // Caso tenha documento anexado mas clicaram excluiram
                        // Caso tenha documento anexado mas teve alteração
                        // Caso não teve alteração
                        if (this.$refs.file)
                          if (this.documentoAnexado.length > 0) {
                            useJwt
                              .delete('cadastro/instituto/deleteDocumentoCRP', this.dados.id)
                              .then(() => {})
                              .catch((error) => {
                                console.error(error)
                              })
                          }

                        if (this.$refs.file)
                          if (this.documentoCRP.length != 0) {
                            const formData = new FormData()
                            formData.append('nome', 'CRPDocumento' + this.dados.sigla)
                            formData.append('institutoId', this.dados.id)
                            formData.append('file', this.documentoCRP)

                            useJwt
                              .postDocumento(`cadastro/instituto/uploadDocumentoCRP`, (event) => {}, formData)
                              .then((response) => {
                                this.$refs['modal-cadastro'].hide()
                              })
                              .catch((error) => {
                                possuiErro = true
                                this.$message.error(error.response.data.error)
                              })
                          }
                      }
                      if (uploadOk == true) {
                        this.carregarGrid()
                        this.msgSuccess('Dados alterados com sucesso!')
                        this.loading = !this.loading
                      } else if (possuiErro == false) this.carregarGrid()
                      this.msgSuccess('Dados alterados com sucesso!')
                    })
                    .catch((error) => {
                      this.loading = !this.loading
                      console.error(error)
                      var Title = 'Erro ao realizar a alteração na Unidade Gestora!'
                      this.$message.error(Title)
                    })
                })
                .catch((error) => {
                  console.error(error)
                })
            } else {
              var Title = 'Os dados bancários não estão corretos!'
              this.$message.error(Title)
              reject()
            }
          }

          if (possuiErro) {
            this.loading = !this.loading
            var Title = 'Ocorreu um erro ao cadastrar a Unidade Gestora!'
            this.$message.error(Title)
          }
          this.carregarGrid()
          if (!this.$refs.file || this.$refs.file.files.length == 0) {
            this.$refs['modal-cadastro'].hide()
            this.loading = !this.loading
          }
        } else if (this.bancarioitems.countEntrada || this.bancarioitems.countSaida) {
          this.loading = !this.loading
          var Title = 'Erro: É necessário ter ao menos uma conta de entrada e uma conta da saída!'
          this.$message.error(Title)
        }
      },
      validationDadosCadastrais() {
        return new Promise((resolve, reject) => {
          this.$refs.cadRules.validate().then((success) => {
            if (
              success &&
              this.isCNPJValid() &&
              this.isCPFValid() &&
              this.isDateValid(this.dados.dtInicioGestao) &&
              this.isEmailValid() &&
              this.isPhoneValid()
            ) {
              this.loading = !this.loading
              resolve(true)
            } else {
              this.loading = !this.loading
              reject()
              var Title
              if (!success) {
                ;(Title = 'Erro: Há campos não preenchidos no formulário.'), this.$message.error(Title)
              } else if (!this.isCNPJValid()) {
                Title = 'O CNPJ digitado não é válido'
                this.$message.error(Title)
              } else if (!this.isCPFValid()) {
                Title = 'Erro: O CPF digitado não é válido.'
                this.$message.error(Title)
              } else if (this.isDateValid(this.dados.dtInicioGestao) == false) {
                ;(Title = 'Erro: A data inserida para o inicio de gestão não é válida. '), this.$message.error(Title)
              } else if (!this.isEmailValid()) {
                ;(Title = 'Erro: O e-mail digitado não é válido. '), this.$message.error(Title)
              } else if (!this.isPhoneValid()) {
                ;(Title = 'Erro: O telefone digitado não é válido. '), this.$message.error(Title)
              }
            }
          })
        })
      },
      validationCRP() {
        return new Promise((resolve, reject) => {
          this.$refs.crpRules.validate().then((success) => {
            const crpValidade = this.dados.crpValidade ? formatarDataGlobal(this.dados.crpValidade) : null
            const crpValidadeAno = this.dados.crpValidade ? crpValidade.getFullYear() : null
            const anoLimite = new Date().getFullYear() + 1
            let mensagem
            let today = formatarData(new Date())
            today = formatarDataGlobal(today)

            const arquivoAnexado = this.$refs.file ? this.$refs.file?.files[0] : null

            if (crpValidade) {
              if (crpValidade == 'Invalid Date') {
                mensagem = 'Erro: A data inserida para o CRP não é válida!'
                console.error(mensagem)
                this.$message.error(mensagem)
                this.loading = false
                return reject()
              }

              if (!dateFns.isValid(crpValidade)) {
                mensagem = 'Erro: A data inserida para o CRP não é válida!'
                console.error(mensagem)
                this.$message.error(mensagem)
                this.loading = false
                return reject()
              }

              if (crpValidade < today) {
                mensagem = 'Erro: Data de validade do CRP não pode ser menor, igual, ou com ano superior ao permitido legal!'
                console.error(mensagem)
                this.$message.error(mensagem)
                this.loading = false
                return reject()
              }

              if (crpValidadeAno > anoLimite) {
                mensagem = 'Erro: O Ano da validade do CRP não pode ser superior ao permitido legal!'
                console.error(mensagem)
                this.$message.error(mensagem)
                this.loading = false
                return reject()
              }

              if (!arquivoAnexado && !this.documentoCRP.id) {
                mensagem = 'Erro: Ao informar a validade do CRP é necessário anexar o Documento do CRP!'
                console.error(mensagem)
                this.$message.error(mensagem)
                this.loading = false
                return reject()
              }
            }

            resolve(true)
          })
        })
      },
      validationEndereco() {
        return new Promise((resolve, reject) => {
          this.$refs.enderecoRules.validate().then((success) => {
            if (success) {
              this.loading = true
              if (this.dados.id == undefined) {
                this.loading = false
                resolve(true)
              } else {
                this.loading = false
                resolve(true)
              }
            } else {
              reject()
            }
          })
        })
      },
      validationBanco() {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      msgSuccess(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'UserCheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      pesquisarInstituto() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      buscarCEP() {
        if (this.dados.cep.length == 10) {
          useJwt
            .get(`utils/cep/${this.dados.cep.replace(/\D/g, '')}`)
            .then((response) => {
              this.carregarCidadeEndereco(response.data)
              this.dados = {
                ...this.dados,
                logradouro: response.data.logradouro,
                bairro: response.data.bairro,
                uf: response.data.uf,
                cidade: response.data.localidade,
              }
            })
            .catch((error) => {
              console.error(error)
              this.$message.warning('CEP não encontrado! Por favor, preencha os campos manualmente.')
            })
        }
      },
      carregarBancos() {
        useJwt
          .get('utils/bancos')
          .then((response) => {
            this.banco = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarCidadeEndereco(item) {
        useJwt
          .get(`utils/cidades/${item}`)
          .then((response) => {
            this.cidade = response.data
              .flatMap((valor) => valor.nome)
              .reduce((acumulador, atual) => {
                if (!acumulador.includes(atual)) acumulador.push(atual)
                return acumulador
              }, [])
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('cadastro/instituto/Getpesquisar', this.pesquisar)
          .then((response) => {
            this.items = response.data
            this.totalRows = this.items.length

            this.items = this.items.sort((current, next) => current.nome.localeCompare(next.nome))
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      carregarGridBancaria(instId) {
        useJwt
          .get(`cadastro/instituto/bancarioInst/${instId}`)
          .then((response) => {
            this.bancarioitems = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoRegistro() {
        this.dados = {}

        // this.dados.cnpj = "25511149000166"
        // this.dados.nome = "TESTE"
        // this.dados.razaoSocial = "TESTE"
        // this.dados.sigla = "TES"
        // this.dados.nomeResponsavel = "TESTE"
        // this.dados.cpfResponsavel = "394.631.551-80"
        // this.dados.cargoResponsavel = "ADM"
        // this.dados.dtInicioGestao = "2005-05-15"
        // this.dados.emailResponsavel = "TESTE@TESTE.COM"
        // this.dados.telefone = 65996012187

        this.bancarioitems = []
        this.$refs['modal-cadastro'].show()
      },
      excluirVerifica(item) {
        useJwt
          .get(`cadastro/orgao/buscaPorInstitutoId/${item}`)
          .then((response) => {
            this.buscaOrgao = response.data.length

            if (this.buscaOrgao > 0) {
              var Title
              Title =
                'Erro: A Unidade Gestora não pode ser deletado pois tem Órgãos vinculados ao seu cadastro. Delete os Órgãos e depois a Unidade Gestora!'
              this.$message.error(Title)
            } else if (this.buscaOrgao < 0 || this.buscaOrgao == 0) {
              this.excluir(item)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/instituto`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      editar(item) {
        //Dados do instituto
        useJwt
          .edit(`cadastro/instituto`, item)
          .then((response) => {
            this.dados = {
              ...response.data,
              cidade: response.data.cidade,
              dtInicioGestao: response.data.dtInicioGestao ? formatarData(response.data.dtInicioGestao) : null,
              crpValidade: response.data.crpValidade ? formatarData(response.data.crpValidade) : null,
              crpStatus: response.data.crpStatus == 'Valido' ? 'Válido' : 'Inválido', //Valido, NaoValido
            }

            this.carregarCidadeEndereco(this.dados.uf)

            // Dados CRP
            useJwt
              .get(`cadastro/instituto/downloadDocumentoCRP/${item}`)
              .then((response) => {
                if (response.data) {
                  this.documentoAnexado = response.data
                  this.documentoCRP = response.data
                }
                this.$refs['modal-cadastro'].show()
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
        // Dados bancários
        this.carregarGridBancaria(item)
      },
      // downloadArquivo() {
      //   window.open(this.documentoCRP.s3url)
      // },
      async downloadArquivo() {
        const fileName = this.documentoCRP.fileName
        const s3Key = this.documentoCRP.s3Key
        this.isBusy = true
        await downloadFileAWS(s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      excluirArquivo() {
        this.documentoCRP = []
        this.filelist.splice(0, 1)
      },
    },
  }
</script>
<style lang="scss">
  .breakWord {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .flexRowReverse {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }

  @import '@core/scss/vue/libs/vue-select.scss';
</style>
